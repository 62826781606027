
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ref, defineComponent, onMounted, computed, watch } from "vue";
import { Actions, Modules, Mutations } from "@/store/enums/StoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import NMEventDetailsModal from "@/views/EventDetailsModal.vue";
import Pagination from "@/components/Pagination.vue";
import { formatDateTime } from "@/core/helpers/date";
import ViewEventUsersModal from "@/views/ViewEventUsersModal.vue";

export default defineComponent({
  name: "my-events-history",
  components: {
    Pagination,
    NMEventDetailsModal,
    ViewEventUsersModal,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    let search = ref("");
    let currentPageURL = ref("");
    let visibility = ref<null | any>(null);
    let online = ref<boolean>(false);

    visibility.value = route.query?.visibility;
    online.value = route.query?.online == "true" ? true : false;

    let isLoading = computed(() => {
      return store.getters[Modules.EVENT + "getIsLoading"];
    });
    let events = computed(() => {
      return store.getters[Modules.EVENT + "getEvents"];
    });
    let eventsMeta = computed(() => {
      return store.getters[Modules.EVENT + "getMeta"];
    });
    let eventUsers = computed(() => {
      return store.getters[Modules.EVENT + "getEventUsers"];
    });

    onMounted(() => {
      setCurrentPageTitle("My Events History");
    });

    fetchRecords();

    function searchNow() {
      currentPageURL.value = "";
      fetchRecords();
    }

    function gotoPage(url) {
      currentPageURL.value = url;
      fetchRecords();
    }

    function fetchRecords() {
      store.dispatch(Modules.EVENT + Actions.FETCH_MY_EVENTS_HISTORY, {
        search: search.value,
        visibility: visibility.value,
        online: online.value,
        url: currentPageURL.value,
      });

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }

    function fetchEventUsers(id) {
      store.dispatch(Modules.EVENT + Actions.FETCH_EVENT_USERS, id);
    }

    watch(visibility, (value) => {
      currentPageURL.value = "";
      fetchRecords();
    });

    watch(online, (value) => {
      currentPageURL.value = "";
      fetchRecords();
    });

    const setEventDetailsIndex = (index) => {
      store.commit(Modules.EVENT + Mutations.SET_EVENT_DETAILS_INDEX, index);
    };

    return {
      formatDateTime,
      search,
      isLoading,
      events,
      eventsMeta,
      visibility,
      online,
      eventUsers,
      searchNow,
      gotoPage,
      fetchEventUsers,
      setEventDetailsIndex,
    };
  },
});
