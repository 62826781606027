
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import "@vuepic/vue-datepicker/dist/main.css";
import { formatDateTime } from "@/core/helpers/date";

export default defineComponent({
  name: "ViewEventUsersModal",
  props: ["eventUsers", "isLoading"],
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const modalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    return {
      formatDateTime,
      loading,
      formRef,
      modalRef,
    };
  },
});
